import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import { RedBuyBtn } from '@/components/index'
const imgs_pc = []
const imgs_mobile = []
for (let i = 1; i < 11; i++) {
    imgs_pc.push(`${process.env.resourceUrl}images/campaign/newlife_pc_${i}.webp`)
}
for (let i = 1; i < 8; i++) {
    imgs_mobile.push(`${process.env.resourceUrl}images/campaign/newlife_mobile_${i}.webp`)
}

function Index(props) {
    const [isWideScreen, setIsWideScreen] = useState(false);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    function handleResize() {
        const aspectRatio = window.innerWidth / window.innerHeight;
        const ifPcwidth=window.innerWidth>800
        setIsWideScreen(ifPcwidth);
    }

    return (
        <Layout menuBlack={true}>
            <RedBuyBtn cate='jp_newlife'/>
            <div style={{ background: "#f2f2f2" ,paddingTop:'80px'}}>
                <div style={{ width: '100%', maxWidth: "1000px", margin: "0 auto" }}>
                    {isWideScreen ? (
                        imgs_pc.map((item, index) => (
                            <img src={item} key={`newlifeimg_pc${index}`} width='100%'  />
                        ))
                    ) : (
                        imgs_mobile.map((item, index) => (
                            <img src={item} key={`newlifeimg_mobile${index}`} width='100%' style={{marginTop:index==6?'-1px':'0'}}/>
                        ))
                    )}

                </div>
            </div>
        </Layout>
    )
}

export default Index